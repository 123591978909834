import React, { useEffect, useState } from "react";
import signbg from "../../../assets/images/signbg.png";
import silogo from "../../../assets/images/si-logo.svg";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import FavoriteTwoToneIcon from "@mui/icons-material/FavoriteTwoTone";
import {
  Card,
  CardGroup,
  Container,
  Row,
  div,
  Col,
  h5,
  p,
  h2,
} from "react-bootstrap";
import DrawerRight from "../components/DrawerRight";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import BlankSpace from "../components/BlankSpace";
import BottomNav from "../components/BottomNav";
import ScrollToDown from "components/scroll/ScrollToDown";
import LogoCenter from "../components/LogoCenter";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import logo from "../../../assets/images/logo.png";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Fab from "@mui/material/Fab";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import FontSizeChanger from "react-font-size-changer";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import alertify from "alertifyjs";
import { useCookies } from "react-cookie";
import "alertifyjs/build/css/alertify.css";

const serverId = process.env.REACT_APP_SERVER_ID;
const siteId = process.env.REACT_APP_SITE_ID;
const storeId = process.env.REACT_APP_STORE_ID;

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      <br></br>
      Made with <FavoriteTwoToneIcon sx={{ color: "#820813" }} /> by<br></br>
      <Link color="inherit" target="_blank" href="https://servingintel.com/">
        <img className="silogo" src={silogo} alt="ServingIntel" />
        <br></br>
        <br></br>
      </Link>{" "}
    </Typography>
  );
}

const StyledFab = styled(Fab)({
  position: "absolute",
  zIndex: 1,
  top: 0,
  left: 0,
  right: 0,
  margin: "0 auto",
  width: "360px",
  backgroundColor: "transparent !important",
  boxShadow: "none !important",
  textTransform: "none",
});

const theme = createTheme();

export const LoginCode = () => {
  const history = useHistory();
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
  };

  const [newFontSize, setFontSize] = useState(16);
  const increaseFont = () => setFontSize(newFontSize + 2);

  const firstName = sessionStorage.getItem("firstName");
  const roomNumber = sessionStorage.getItem("roomNumber");

  const [pinCode, setPinCode] = useState();
  const [pinCodeConfirm, setPinCodeConfirm] = useState();
  const location = useLocation();

  const [cookie, setCookie, removeCookie] = useCookies();

  const changePin = () => {
    removeCookie("firstName");
    removeCookie("roomNumber");
    history.push("/UpdateCode");
  };

  const logOut = () => {
    removeCookie("firstName");
    removeCookie("roomNumber");
    sessionStorage.removeItem("pinCode");
    history.push("/");
  };

  const validateAccount = () => {
    // console.log(firstName)
    // console.log(roomNumber)

    if (!pinCode) {
      alertify.alert("Error", "Please input PIN CODE", function () {});
      return;
    }

    const url =
      "https://ecommv2.servingintel.com/customer/login/" +
      serverId +
      "/" +
      siteId +
      "/" +
      storeId +
      "/" +
      firstName +
      "/" +
      roomNumber;
    axios
      .get(url)
      .then((response) => {
        console.log(response);
        if (response.data === null) {
          alertify.alert("Error", "Invalid PIN CODE", function () {});
          return;
        }

        const customer = response.data;
        if (String(customer.SIC_PIN) === String(pinCode)) {
          // && String(customer.SIC_PIN) === String(pinCodeConfirm)
          sessionStorage.setItem("pinCode", true);
          // sessionStorage.setItem('pinCodeConfirm',customer.SIC_PIN)
          history.push("/menu");
        } else {
          alertify.alert("Error", "Invalid PIN CODE", function () {});
        }
      })
      .catch((error) => {});
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Grid
          container
          component="main"
          sx={{ fontSize: "${newFontSize}px", height: "100vh" }}
        >
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "left",
              left: "0",
              right: "0",
              backgroundImage: "url(" + signbg + ")",
            }}
          />

          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              id="target"
              sx={{ my: 8, mx: 4, display: "flex", flexDirection: "column" }}
            >
              <img src={logo} className="SL-logo" alt="logo" />
              <br />
              <Typography
                className="content"
                sx={{ mt: -1, textAlign: "center" }}
                component="h4"
                variant="h4"
              >
                Welcome back! <br /> {firstName.toUpperCase()} <br /> It's nice
                to see you!
              </Typography>
              <br />
              <Typography
                className="content"
                sx={{ mt: -1, textAlign: "center" }}
                component="h5"
                variant="h5"
              >
                Please enter your PIN (4 Digit)
              </Typography>

              <Box
                className="content"
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  type="text"
                  required
                  fullWidth
                  name="pinCode"
                  label="Enter Code"
                  id="pinCode"
                  inputProps={{ maxLength: 4 }}
                  variant="standard"
                  onKeyUp={(e) => {
                    setPinCode(e.target.value);
                  }}
                />

                <Button
                  size="large"
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 5, mb: 2, fontWeight: "600" }}
                  onClick={() => {
                    validateAccount();
                  }}
                >
                  <Typography className="content" sx={{ fontWeight: "600" }}>
                    LOGIN
                  </Typography>
                </Button>

                <Button
                  size="large"
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 0, mb: 2, fontWeight: "600" }}
                  onClick={() => {
                    logOut();
                  }}
                >
                  <Typography className="content" sx={{ fontWeight: "600" }}>
                    NOT ME
                  </Typography>
                </Button>

                <Button
                  size="large"
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 0, mb: 2, fontWeight: "600" }}
                  onClick={() => {
                    changePin();
                  }}
                >
                  <Typography className="content" sx={{ fontWeight: "600" }}>
                    Forgot / Change PIN
                  </Typography>
                </Button>
              </Box>
            </Box>

            <Copyright
              id="copyrignt"
              position="centered"
              sx={{ top: "auto", bottom: 0 }}
            />

            <FontSizeChanger
              targets={["#target .content"]}
              onChange={(element, newValue, oldValue) => {
                console.log(element, newValue, oldValue);
              }}
              options={{
                stepSize: 2,
                range: 5,
              }}
              customButtons={{
                up: (
                  <AppBar
                    position="fixed"
                    sx={{
                      width: "90px",
                      boxShadow: 0,
                      top: "auto",
                      bottom: 237.5,
                      backgroundColor: "transparent",
                    }}
                  >
                    <Toolbar>
                      <Box sx={{ flexGrow: 1 }} />
                      <IconButton aria-label="add font size">
                        <Fab
                          color="primary"
                          aria-hidden="true"
                          sx={{ backgroundColor: "#3D5309" }}
                        >
                          <AddCircleOutlineIcon />
                        </Fab>
                      </IconButton>
                    </Toolbar>
                  </AppBar>
                ),
                down: (
                  <AppBar
                    position="fixed"
                    sx={{
                      width: "90px",
                      boxShadow: 0,
                      top: "auto",
                      bottom: 180,
                      backgroundColor: "transparent",
                    }}
                  >
                    <Toolbar>
                      <Box sx={{ flexGrow: 1 }} />
                      <IconButton>
                        <Fab
                          color="primary"
                          aria-hidden="true"
                          sx={{ backgroundColor: "#765F00" }}
                        >
                          <RemoveCircleOutlineIcon />
                        </Fab>
                      </IconButton>
                    </Toolbar>
                  </AppBar>
                ),
              }}
            />
          </Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
};
